@import './utils/_variables';
@import './utils/_texts';
@import './utils/breakpoints';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Barlow;
  background-color: $color-black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
textarea {
  margin: 0;
  letter-spacing: -0.02em;
}

label {
  margin: 0px;
}

// layout
.vertical-center {
  display: flex;
  align-items: center;
}
.vertical-end {
  display: flex;
  align-items: flex-end;
}
.horizontal-center {
  display: flex;
  justify-content: center;
}
.horizontal-end {
  display: flex;
  justify-content: flex-end;
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.mb-20 {
  margin-bottom: 20px;
}

// button
button {
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
  &:hover,
  &:focus {
    outline: none !important;
  }
}

// div
div {
  &:focus {
    outline: none;
  }
}

// image
img {
  box-sizing: border-box;
}

// input, textarea
textarea,
input {
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  width: 100%;
  &:hover,
  &:focus {
    outline: none !important;
  }
}

// dropdown
.dropdown {
  position: initial;
}

// custom scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $color-gray;
  position: absolute;
  @media (max-width: $small) {
    height: 4px;
  }
}

::-webkit-scrollbar-track {
  width: 10px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: $color-gray-20;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-gray-20;
}

.bd-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: rgba(0, 0, 0, 0.8);
  .bd-modal-inner {
    width: 100%;
    max-width: 350px;
    background: $color-gray-10;
    .bd-modal-header {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 15px 15px 20px 15px;
      button {
        margin-left: 10px;
      }
    }
    .bd-modal-body {
      padding: 15px;
    }
    .input-holder {
      label {
        font-size: 11px;
        color: $color-gray-30;
        margin-bottom: 10px;
      }
      input,
      textarea {
        background: $color-gray;
        border-radius: 5px;
      }
      input {
        height: 37px;
        padding: 0 20px;
        color: $color-gray-60;
        font-size: 15px;
      }
      textarea {
        resize: none;
        padding: 20px;
        color: $color-gray-30;
        font-size: 14px;
      }
    }
  }
  .bd-modal-form {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
  }
}
.react-datepicker__header {
  background-color: $color-gray-20 !important;
  border-color: $color-gray-40;
  height: 65px;
  border: none !important;
  div {
    color: $color-gray-60;
    background-color: $color-gray-20;
  }
}
.react-datepicker {
  margin: 15px 0;
  background-color: $color-gray-90 !important;
  border: none !important;

  div {
    color: $color-gray-60;
  }
  .react-datepicker__month-container {
    background-color: $color-gray-90;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day:hover {
    background-color: $color-green;
  }
  .react-datepicker__month-option:hover {
    background-color: $color-green;
  }
  .react-datepicker__year-option:hover {
    background-color: $color-green;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
}
.react-datepicker__time-list {
  background-color: $color-gray-90;
  .react-datepicker__time-list-item--selected,
  .react-datepicker__time-list-item:hover {
    background-color: $color-green !important;
  }
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__time-container {
  border-color: $color-gray-40;
  border: none !important;
}
.react-datepicker__input-container {
  padding: 11px 16px;
}
.text-decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
